import React from "react"
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../layouts/layout"
import SEO from "../layouts/seo"

const PricingPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title="Pricing" />
      <article className="article text--center">
        <header className="article-header">
          <h1 className="heading heading--md">{t("pricing-page.header")}</h1>
          <p className="paragraph paragraph--lg">
            <Trans>pricing-page.header-small</Trans>
          </p>
          <p className="paragraph paragraph--sm">
            <Trans>pricing-page.header-paragraph</Trans>
          </p>
        </header>

        <ul className="pricing-list">
          <li className="basic">
            <div className="data name">
              <Trans>pricing-page.basic</Trans>
            </div>
            <div className="data price">
              <strong>0€</strong>
              <Trans>pricing-page.per-month</Trans>
            </div>
            <div className="data benefit">
              <Trans>pricing-page.cost-per-delivery</Trans>
              <strong>15 Cents</strong>
            </div>
            <div className="data additional">
              <Trans>pricing-page.vat</Trans>
            </div>
            <div className="data action-button">
              <a
                href="https://route-optimizer.app/users/sign_up"
                className="btn btn--sm btn--secondary"
              >
                <Trans>start-now</Trans>
              </a>
            </div>
          </li>

          <li className="partner">
            <div className="data name">
              <Trans>pricing-page.partner</Trans>
            </div>
            <div className="data price">
              <strong>0€</strong>
              <Trans>pricing-page.per-month</Trans>
            </div>
            <div className="data benefit">
              <Trans>pricing-page.cost-per-delivery</Trans>
              <strong>
                <Trans>pricing-page.individual</Trans>
              </strong>
            </div>
            <div className="data additional">
              <Trans>pricing-page.get-offer</Trans>
            </div>
            <div className="data action-button">
              <a
                href="mailto:sales@tiramizoo.com?subject=Requesting information about RO API Partnership&body=I would like to know more about the Route Optimizer Partnership. Please contact me."
                className="btn btn--sm btn--primary"
              >
                <Trans>pricing-page.contact-sales</Trans>
              </a>
            </div>
          </li>
        </ul>
      </article>
    </Layout>
  )
}

export default PricingPage
